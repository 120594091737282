<template>
  <v-container class="container-profile">
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-card-subtitle>My Plan</v-card-subtitle>

          <v-card-title class="text-h5" v-if="isAdminOrContributor">
            {{ getRoleTitle(userProfile.role) }}
          </v-card-title>

          <v-card-title class="text-h5" v-if="!isAdminOrContributor">
            {{ activeSubscription ? 'Premium | ' + activeSubscriptionName(activeSubscription.plan) : "Free Tier" }}
          </v-card-title>

          <v-divider></v-divider>

          <v-card-subtitle v-if="activeSubscription">
            <v-list-item-content>
              <v-list-item-subtitle>
                Subscription<strong> - ₱ {{ activeSubscription.cost }}/{{ minify(activeSubscription.contract.text) }}</strong>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Your next billing date: <strong>{{ convertDate(activeSubscription.endDate) }}</strong>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-card-subtitle>

          <v-card-subtitle v-else>
            {{ getSubscriptionInfo(userProfile.role) }}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn outlined rounded small :disabled="!activeSubscription" @click="handleButtonClick(userProfile)" v-if="!isAdminOrContributor">
              {{ activeSubscription ? 'Change Plan' : 'Upgrade my plan' }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="show = !show" v-if="!isAdminOrContributor">
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
          
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>
              <div class="text-overline mt-4">Subscription history</div>
              <v-data-table
                :headers="headers"
                :items="sortedSubscriptions"
                class="elevation-1"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template v-slot:[`item.isActive`]="{ item }">
                  <v-chip :color="item.isActive ? '#A5D6A7' : '#E57373'" text-color="white" small>
                    {{ item.isActive ? 'Active' : 'Expired' }}
                  </v-chip>
                </template>
                <template v-slot:[`item.cost`]="{ item }">
                  <span class="font-weight-bold">{{ formatPrice(item.cost) }}</span>
                </template>
                <template v-slot:[`item.startDate`]="{ item }">
                  <span>{{ convertDate(item.startDate) }}</span>
                </template>
                <template v-slot:[`item.endDate`]="{ item }">
                  <span>{{ convertDate(item.endDate) }}</span>
                </template>
              </v-data-table>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      show: false,
      headers: [
        { text: 'Status', align: 'start', sortable: false, value: 'isActive' },
        { text: 'Plan', sortable: false, value: 'plan' },
        { text: 'Type', sortable: false, value: 'contract.text' },
        { text: 'Cost', sortable: false, value: 'cost' },
        { text: 'Start', sortable: false, value: 'startDate' },
        { text: 'End', sortable: false, value: 'endDate' },
      ],
      sortBy: 'Start',
      sortDesc: false,
    };
  },
  computed: {
    ...mapState(["currentUser", "userProfile"]),
    ...mapGetters(["darkTheme"]),
    activeSubscription() {
      // Ensure this.userProfile and this.userProfile.subscription are defined and are arrays
      if (this.userProfile && Array.isArray(this.userProfile.subscription)) {
        return this.userProfile.subscription.find(sub => sub.isActive);
      }
      return null;
    },
    sortedSubscriptions() {
      // Ensure this.userProfile.subscription is defined and is an array
      if (this.userProfile && Array.isArray(this.userProfile.subscription)) {
        return [...this.userProfile.subscription].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
      }
      return [];
    },
    isAdminOrContributor() {
      return this.userProfile && ['admin', 'contributor'].includes(this.userProfile.role);
    }
  },
  methods: {
    formatPrice(price) {
      return `₱${parseFloat(price).toFixed(2)}`;
    },
    convertDate(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    minify(text) {
      const map = {
        Monthly: 'mo.',
        Quarterly: '3mos.',
        Annually: 'yr.',
      };
      return map[text] || 'Free Plan';
    },
    activeSubscriptionName(plan) {
      const map = {
        Tier1: 'Tier 1 Plan',
        Tier2: 'Tier 2 Plan',
        Tier3: 'Tier 3 Plan',
      };
      return map[plan] || 'Free Plan';
    },
    handleButtonClick(data) {
      const action = this.userProfile.subscription && this.userProfile.subscription.length > 0 ? 'change' : 'subscribe';
      if (confirm(`You will be redirected to another tab to ${action} your plan. Press OK to continue.`)) {
        this.syncUsersToWp(data);
      }
    },
    syncUsersToWp(data) {
      const userEmail = data.email;
      axios.post('/custom-auth-endpoint/wp-json/custom-plugin/v1/store-users', data)
        .then(response => {
          console.log(response.data);
          window.open(`/custom-auth-endpoint/login-page?email=${encodeURIComponent(userEmail)}`, '_blank');
        })
        .catch(error => {
          console.error(error);
          window.open(`/custom-auth-endpoint/login-page?email=${encodeURIComponent(userEmail)}`, '_blank');
        });
    },
    getRoleTitle(role) {
      const roles = {
        admin: 'Admin/Editor',
        contributor: 'Contributor',
      };
      return roles[role] || '';
    },
    getSubscriptionInfo(role) {
      const info = {
        admin: 'Admin/Editor - No subscription information available.',
        contributor: 'Contributor - No subscription information available.',
      };
      return info[role] || 'You are currently not subscribed. - No subscription information available.';
    },
  }
};
</script>

<style scoped>
.fill-height {
  height: 100%;
}
</style>
